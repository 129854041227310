@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
@import "./animation-utils.css";
@import "./input-styles.css";
@import "./grid-style.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set main font */
.main-font {
  font-family: "Poppins", sans-serif !important;
}
html[dir="ltr"] .main-font *,
html #english.english-font,
html .english-font {
  font-family: "Poppins", sans-serif !important;
}
html[dir="rtl"] .main-font *,
html #arabic.arabic-font,
html .arabic-font {
  font-family: "Tajawal", sans-serif !important;
}
html[dir="ltr"] .main-font i {
  font-family: "Icons" !important;
}
html[dir="rtl"] .main-font i {
  font-family: "Icons" !important;
}

html[dir="ltr"] .main-direction {
  direction: ltr !important;
}
html[dir="rtl"] .main-direction {
  direction: rtl !important;
}

.admin-menu {
  background-color: #011c40 !important;
}

h1 {
  @apply text-3xl font-bold !important;
}

h2 {
  @apply text-2xl font-bold !important;
}

h3 {
  @apply text-xl font-bold !important;
}

:focus-visible {
  outline: 0 !important;
}

.ui.disabled.dropdown {
  opacity: 1;
}

.ui.primary.button,
.ui.primary.buttons {
  @apply bg-primary hover:bg-primary-hover !important;
}
/****/
/**playground on sidebar **/
/****/
/****/
.side-navigation-panel-select-inner-option-selected,
.side-navigation-panel-select-option-selected,
.side-navigation-panel-select-option:hover,
.side-navigation-panel-select-inner-option:hover {
  @apply border-none bg-gray-600 transition-colors duration-300 !important;
}

.side-navigation-panel-select-inner-option:hover span {
  @apply text-white !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  @apply border-none mx-6 rounded-full w-52 bg-primary !important;
}

.side-navigation-panel-select-option-selected span {
  @apply text-white !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option {
  @apply border-none mx-6 rounded-full w-60 my-2 text-gray-400 !important;
}

.side-navigation-panel-select-inner-option-selected,
.side-navigation-panel-select-option-selected,
.side-navigation-panel-select-option,
.side-navigation-panel-select-inner-option {
  padding: 0.5rem 1.7rem !important;
  @apply border-none mx-6 rounded-tl-md rounded-bl-md w-full my-2 text-gray-400 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option-selected {
  @apply border-none mx-6 w-full text-white bg-transparent !important;
}
ul.side-navigation-panel-select-inner li {
  position: relative;
}
li:has(.side-navigation-panel-select-inner-option-selected)::after {
  content: " ";
  position: absolute;
  width: 5px;
  height: 30px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;

  top: 50%;
  left: 0;
  transform: translateY(-50%);
  @apply bg-primaryRedColor-default !important;
}

/****/
/****/
/****/
/****/
/****/

.ui.form input:focus,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown .menu {
  @apply border-primary !important;
}
.Sidebar {
  position: fixed !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1 !important;
}

.cofirmDelete {
  @apply bg-red-600 !important;
}

.ui.segment > .ui.dimmer {
  z-index: 10 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option {
  padding: 0.75rem 1rem !important;
}

.w-111 {
  width: 26rem;
}

.remove-spaces-content {
  height: calc(100vh - 70px) !important;
  width: calc(100vw - 16rem) !important;
}

/* Admin Pagination */
.admin-pagination a.item {
  @apply items-center justify-center transition-all ease-in-out duration-200 text-gray-500 !important;
}
.admin-pagination a.active.item {
  @apply border-none bg-red-100 rounded-full justify-center items-center font-normal text-red-600 !important;
}

/* Custom Loader */
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100vh;
}
.lds-hourglass:after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid rgb(46, 44, 44);
  border-color: rgb(44, 43, 43) transparent rgb(133, 128, 128) transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f2421b;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* React Toaster */
.react-toast-notifications__container {
  z-index: 200000 !important;
}

/* Arrow mark */
.ui.popup {
  @apply z-10 !important;
}

.ui.accordion.menu .item .title > .dropdown.icon {
  @apply m-0 !important;
}

/* Fullscreen modal container */
.ui.page.modals .fullscreen-modal-container {
  @apply left-1/2 -translate-x-1/2 !important;
}
