input,
textarea {
  @apply text-labelColor;
  background-color: #f2f2f2 !important;
  border: none !important;
  padding: 15px 10px 15px 10px !important;
  font-family: "Poppins", sans-serif !important;
}

div[role="listbox"].ui.multiple.selection.dropdown,
div[role="combobox"].ui.multiple.selection.dropdown {
  background-color: #f2f2f2 !important;
  border: none !important;
  padding: 10px 5px 10px 10px !important;
  border-radius: 5px !important;
}

div[role="combobox"].ui.multiple.selection.dropdown input {
  padding: 0px 0px 0px 0px !important;
}
div[role="listbox"].ui.selection.dropdown {
  background-color: #f2f2f2 !important;
  border: none !important;
  padding: 15px 5px 15px 10px !important;
  border-radius: 5px !important;
}

div[role="listbox"].ui.selection.dropdown i.dropdown.icon,
div[role="combobox"].ui.selection.dropdown i.dropdown.icon {
  padding-top: 15px !important;
}

.dropdown.active .menu {
  border: none !important;
  box-shadow: none !important;
}

.react-code-input input {
  font-size: 25px !important;
  padding: 7px !important;
}
.secondary-text-color {
  color: #f2421b !important;
}

/* Checkbox styles */
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  border-color: #f2421b !important;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #f2421b !important;
}

.ui.checked.checkbox:not([class*="toggle"]) input:checked ~ label:after {
  @apply -top-0.5 !important;
}

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
  @apply bg-gray-100 !important;
}
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  height: 2.2rem !important;
  width: 4.6rem !important;
}

.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  width: 1.9rem !important;
  height: 1.9rem !important;
  @apply bg-gray-300 shadow-none !important;
}
.ui.toggle.checkbox input.hidden:checked ~ .box:before,
.ui.toggle.checkbox input.hidden:checked ~ label:before {
  @apply bg-transparent ring-2 ring-primaryRedColor-default !important;
}
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  top: 2px !important;
}
.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  @apply bg-primaryRedColor-default left-10 !important;
}

.auth-input input {
  @apply rounded-xl !important;
}
