.grid-container {
  display: grid !important;
  grid-template-areas:
    "title"
    "list"
    "total";
  grid-template-rows: 30px 1fr 60px;
}

.main-grid-container {
  display: inline-grid !important;
  grid-template-areas:
    "title"
    "filter"
    "list"
    "total";
  grid-template-rows: 68px 0.05fr 1fr 80px;
  height: calc(100vh - 70px);
}

.content-title {
  grid-area: title;
}

.content-list {
  grid-area: list;
}

.content-total {
  grid-area: total;
}

.content-filter {
  grid-area: filter;
}
