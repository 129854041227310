.animate-in {
  animation: fade-in 200ms ease-in-out forwards;
}

.animate-out {
  animation: fade-out 400ms ease-in-out forwards;
}

.scale-in {
  animation: scale-in 250ms ease-in-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes scale-in {
  from {
    opacity: 0;
    transform: scale(0.98) /* translateY(10px) */;
  }
}
